 import { Avatar, Backdrop, Box, Button, CardMedia, CircularProgress, IconButton, Typography } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { Btn, IconBtn, PageHeader } from "../../components"
import { API, HandleCreate64ForState } from "../../config/functions"
import { useData } from "../../config/Provider"
import Vals from "../../config/Vars"
import { Style } from "./Style"
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

const  AccountSettings = ({nav}) =>  {
 
    const [ load , setLoad ] = useState(true)
    const [ profilePic , setProfilePic ] = useState([])
    const [ cover , setCover ] = useState([])
    const [ data , setData ] = useState([])
    const {user} = useData()
    document.title='SYPHEX | Settings'

    const getProfileData = async () => {
        setLoad(true)
        await API.get( `/u/` , Vals?.GET_HEADER )
        .then( ( response ) => {
        
            setData(response?.data?.data[0])
            
            if (response?.data?.data[0]?.user_cover_photo) {
                setCover({base64:`${Vals?.M_API}/covers/${response?.data?.data[0]?.user_cover_photo}`,empty:true})
            } else {
                setCover({base64:`/images/cover.png`,empty:false})
            }
            if (response?.data?.data[0]?.user_profile_pic) {
                setProfilePic({base64:`${Vals?.M_API}/profile-pictures/${response?.data?.data[0]?.user_profile_pic}`,empty:true})
            } else {
                setProfilePic({base64:`/logo.png`,empty:false})
            }
            setLoad(false)
        })

        .catch((e) => console.log(e) )
    }

    useEffect(() => {
        getProfileData()
    },[ ])
 
    const imageIcon = HandleCreate64ForState(setProfilePic)
    const imageCover = HandleCreate64ForState(setCover)
    
    const deleteImage = () => {
        setProfilePic({base64:`/images/cover.png`,empty:false})
    }

    const deleteCoverimg = () => {
        setCover({base64:`/images/cover.png`,empty:false})
    }
 
    const deactivateAlert = () => {

    }
 
    
    const save = async () => {

        setLoad(true)

        try {

            var formData = new FormData()

            if (!cover?.empty) {
                formData.append('deleteCover', 'y' );
            }

            if (cover?.blob) {
                formData.append('cover', cover?.blob, 'cover');
            } else if (cover?.base64 && !cover?.blob) {
                formData.append('cover', cover?.base64 );
            } else {
                formData.append('cover', '' );
            }

            if (!profilePic?.empty) {
                formData.append('deleteProfilePic', 'y' );
            }
 
            if (profilePic?.blob) {
                formData.append('profilePic', profilePic?.blob, 'image');
            } else if (profilePic?.base64 && !profilePic?.blob) {
                formData.append('profilePic', profilePic?.base64 );
            } else {
                formData.append('profilePic', '' );
            }

            await API.post(`/save-profile-settings/ `, formData , Vals?.POST_UPLOAD_HEADER )
            
            .then(function (response) {

                if (response?.data?.status === true ){ 
                    nav(`/profile/${user}`)
                } else {
                    console.log( 'Error' , response?.data?.msg )
                }

                setLoad(false)
            
            }).catch(function (error) {
                console.log(error)
            })

        } catch (e) {
            console.log(e)
        }
        
    }

    if (!load)
    return ( 
        <Box sx={[ , {height:'100%', }]}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
        >
      <CircularProgress color="inherit" />
      </Backdrop>
            <PageHeader {...{title:'Profile Settings',func:save,btnTitle:'Save'}} />
            
            <Box sx={[Style?.inputItem , { borderColor:'primary.border' }]}>

                <Box sx={Style?.Label}>
                    <Typography sx={{color:'primary.text'}}> Username </Typography>
                </Box>

                <Box sx={Style?.input}>
                    <Typography sx={{color:'primary.text',textAlign:'left'}}> @{data?.user_username} </Typography>
                </Box>

            </Box>
            <Box>
                <CardMedia component="img" sx={Style?.cover} image={cover?.base64} alt='cover'/> 
            </Box>
            <Box sx={{
                display:'flex' ,flexDirection:'column',alignItems:'center'  ,mt:2 , justifyContent:'center'
            }}>
                {cover?.empty ? 
                    (<IconBtn {...{title:'delete',func:deleteCoverimg,icon:<DeleteTwoToneIcon/>}} />) : 
                    (
                    
                    <IconButton color="primary" aria-label="upload cover picture" component="label" sx={{backgroundColor:'primary.main'}}>
                        <input hidden accept="image/*" type="file" name="coverPhoto" onChange={imageCover}/>
                        <ImageTwoToneIcon sx={{color:'primary.text'}}/>
                    </IconButton>
                    )
                }
            </Box> 

            <Box sx={{marginVertical : 25 , width:'100%'  , borderBottomWidth:0.2 , borderColor:'primary.border' }} />

            <Box sx={{ width:'100%' ,display:'flex' ,flexDirection:'column',alignItems:'center'  ,mt:6 , justifyContent:'center' }}>
                
                <Avatar  src={profilePic?.base64 } sx={{ width: 120, height: 120, borderRadius: 100 }}/>

                <Box sx={{ width:'100%' ,display:'flex' ,justifyContent:'center',flexDirection:'row' , alignItems:'center',marginTop:3}} > 

                {profilePic?.empty ? 
                        <IconBtn {...{title:'delete',func:deleteImage,icon:<DeleteTwoToneIcon/>}} />  
                    : 
                    <>
                    <IconButton color="primary" aria-label="upload profile picture" component="label" sx={{backgroundColor:'primary.main'}}>
                        <input hidden accept="image/*" type="file"  name='profilePic' onChange={imageIcon}/>
                        <ImageTwoToneIcon sx={{color:'primary.text'}}/>
                    </IconButton>
                    </>
                    }
                
                </Box>

                <Box mt={5}>
                    <Btn {...{title:'DEACTIVATE ACCOUNT',func:deactivateAlert}} />
                </Box>

            </Box>
        </Box>

    )
  
}
 
export default AccountSettings