export const Style = {
 

    Label  : {height:70 , display:'flex' , width:'25%' , justifyContent:'center' , flexDirection:'row' , alignItems:'center'} ,

    inputItem: {height:'auto' , width:'100%', display:'flex' , flexDirection:'row' , alignItems:'center' , justifyContent:'space-between',borderWidth: 0.5 ,mb:2, BorderColor:'peimary.border',borderStyle:'solid' },

    input : {height:70 , width:'75%' , flexDirection:'row' , display:'flex', justifyContent:'flex-start' ,alignItems:'center' },

    cover : {
        borderWidth: 0.2,
        borderStyle: `solid`,
        width:'100%',
        height:200,
        borderColor: `gray`,
        borderRadius:3 ,
    }
 

 
}