import { IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import { Btn, PageHeader, TextInput } from '../../components'
import SendIcon from '@mui/icons-material/Send';
import { Style } from './Style';
import { useData } from '../../config/Provider';
import { CalculateTime } from '../../config/functions';
import FiberSmartRecordTwoToneIcon from '@mui/icons-material/FiberSmartRecordTwoTone';



const Chat = () => {
    const [data , setData ] = useState([])
    const [msgText , setMsgText ] = useState('')
    const chatContainerRef = useRef(null)
    const [ page , setPage ] = useState(0)
    const { user , socket , online } = useData()
    document.title='SYPHEX | Chat'

    useEffect(() => {
        
        socket?.emit('Oldmessage' , { page : page })
        
        socket?.on('RCOLDmessage', (msg) => {
            setData( msg , ...data  )
            setTimeout(() => {
                chatContainerRef.current.scrollIntoView({ behavior: 'smooth' })
            }, 1000);
        })

        socket?.on('RCmessage', (msg) => {
            setData((msgs) => [ ...msgs , msg  ])
            setTimeout(() => {
                chatContainerRef.current.scrollIntoView({ behavior: 'smooth' })
            }, 1000);
        })

    } , [])

    const sendMsg = () => {
        if (!msgText.trim()) return false
        socket.emit('SEmessage', {text:msgText,user_id:user})
        setMsgText('')
    }

    const Msg = ({item}) => (
        <Box sx={ item?.user_id == user ? [Style?.me] : [Style?.not] }>
            <Typography sx={{color:'white',fontSize:12}}>{item?.sender}</Typography>
            <Typography sx={{color:'white'}}> {item?.text} </Typography>
            <Typography sx={{color:'white',fontSize:10}}>{CalculateTime(item?.date)}</Typography>
        </Box>
    )

    return (
        <Box sx={Style?.Container}>
            <Box sx={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'space-between',
                alignItems:'center'
            }}>
                <Typography variant='h4' color='primary.text'>Chat</Typography>         
                
                <Typography variant='p' color='primary.text' fontSize={18}> {online} <FiberSmartRecordTwoToneIcon sx={{color:'#64d964',fontSize:18}} /> </Typography>         
                
            </Box>
            <Box sx={Style?.ChatContainer}>
                {data?.map((item,key) => 
                    <Box sx={{width:'100%',mt:1}} key={key}>
                        <Msg  {...{item}} /> 
                    </Box>
                )}
                <div ref={chatContainerRef}></div>
            </Box>
            <Box sx={Style?.InputContainer}>
                <Box sx={{width:'90%'}}>
                    <TextInput {...{placeholder:'Type...',value:msgText,set:setMsgText}} />
                </Box>
                <IconButton aria-label="send"  color="primary" sx={{zIndex:10}} onClick={sendMsg}>
                    <SendIcon sx={{color:'primary.text',fontSize:25,mt:2.5}} />
                </IconButton>
            </Box>
        </Box>
    )
}

export default Chat