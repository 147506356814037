import React, { useContext, useEffect, useState } from 'react';
import { Style } from './Style';
import { Avatar, Box, Card, CardMedia, IconButton, Typography } from '@mui/material';
import { useData } from '../../config/Provider';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { API , CalculateTime } from '../../config/functions';
import Vals from '../../config/Vars'
import { useParams } from 'react-router';
import Slider from '../slider/Slider';
import AlertsDialog from '../alertsDialog/AlertsDialog';
import VerifiedIcon from '../VerifiedIcon/VerifiedIcon';
const PostCard = ({ nav , post }) => {
    // const context = 
    const [ upVoted , srtUpVoted ] = useState(false) 
    const [ downVoted , srtDownVoted ] = useState(false) 
    const [ seeMore , setSeeMore ] = useState(false) 
    const [ deleted , setDelete ] = useState(false) 
    const [ count , srtCount ] = useState(0) 
    const [ media , setMedia ] = useState([]) 
    const [ showAlert , setShowAlert ] = useState(false) 

    const { Color , logged , user} = useData()

    const [isPlaying, setIsPlaying] = useState(false);
    const des = post?.post_description?.length > 100 ? `${post?.post_description.substring(0,seeMore?64:post?.post_description?.length)} ${seeMore? '...see more' : '' }` : post?.post_description

    const upvoting = async () => {
      if (!logged) {
        return false
      }
      const dataBody = {
        content_id : post?.post_id 
      }

      await API.post( `/post-upvote/`,dataBody , Vals?.POST_HEADER )
      .then( ( response ) => {
        if (response?.data?.status === true) {
          srtUpVoted(response?.data?.checked)
          srtDownVoted(false)
          srtCount(!upVoted?downVoted?count+2:count+1:count-1)
        } else {
        //   Alert.alert('Error','Somthing went wrong !!')
        }
      })
      .catch((e) => console.log(e))

    }

    const downvoting = async () => {
      if (!logged) {
        return false
      }
      const dataBody = {
        content_id : post?.post_id 
      }

      await API.post( `/post-downvote/`,dataBody , Vals?.POST_HEADER )
      .then( ( response ) => {
        if (response?.data?.status === true) {

          srtDownVoted(response?.data?.checked)
          srtUpVoted(false)
          srtCount(!downVoted?upVoted?count-2:count-1:count+1)
          
        } else {
          console.log('Somthing went wrong !!')
        }
      })
      .catch((e) => console.log(e))

    }

    const comments = () => {
        nav(`/post/${post?.post_id}`)
    }

 
    const profilePic = post?.user_profile_pic ? `${Vals?.M_API}/profile-pictures/${post?.user_profile_pic}` :'/logo.png' 
    // const profilePic = 'http://192.168.0.25:8080/profile-pictures/jRgxtS14Tj1bMvHndr24C54330A6-BFDB-44AB-A5B5-9AD163CB865D.jpg' 
    
    useEffect(() => {
      srtCount(post?.votes?post?.votes:0)
      srtUpVoted(post?.voted==='votedUp'?true:false)
      srtDownVoted(post?.voted==='votedDown'?true:false)
      setMedia(JSON.parse(post?.post_media))
      console.log(profilePic)
    } , [] )

    const VisitAccount = () => {
      nav(`/profile/${post?.post_user_id}`)
    }

    const VisitDiscussion = (id) => {
      nav(`/discussion/${post?.discussion_id}`)
    }

    const deletePost = async () =>{
      const dataBody = {
        id : post?.post_id
      }

      await API.post( `/remove-post/`,dataBody , Vals?.POST_HEADER )
      .then( ( response ) => {
        if (response?.data?.status === true) {
          setDelete(true)
        } else {
          console.log('Somthing went wrong !!')
        }
      })
      .catch((e) => console.log(e) )
    }

    const BorderColor = (e) => {
      var val = 'transparent'
      if (e == 0) {
        val = 'transparent'
      } else if ( e == 1 ) {
        val = '#d0cccc'
      } else if ( e == 2 ) {
        val = '#ceb99d'
      } else if ( e == 3 ) { 
        val = '#c9ac2e'
      }
      return val
    }
 
    if (!deleted )
    return (
      <>
        <AlertsDialog {...{
          showAlert:showAlert,
          setShowAlert:setShowAlert,
          alertText:'Are you sure you want to delete this post !',
          alertFunc:deletePost
        }} />
    
        <Card sx={Style?.container }>
          <Box sx={Style?.header}>
            <Box sx={{ display:'flex' , flexDirection:'row',justifyContent:'flex-start',alignItems:'center' , backgroundColor:'primary.black'}}>
              <Box sx={{display:'flex',mr:2}}>
                <Avatar src={profilePic} sx={[Style?.profilePicture , {
                  borderStyle:'solid',
                  borderWidth:2,
                  borderColor:BorderColor(post?.accountLevel)
                }]} alt='lounxadnan' />
                {post?.user_verified === 'y' && ( <VerifiedIcon /> )}
              </Box>
              <Typography sx={[Style?.author,{color:'primary.text'}]} onClick={VisitAccount}  > u/{post?.user_username} -</Typography>
              <Typography ml={1} sx={[Style?.author,{color:'primary.text'}]} onClick={VisitDiscussion}> d/@{post?.discussion_title}</Typography>
            </Box>

            <Box sx={{ display:'flex' ,justifyContent:'flex-end',flexDirection:'row' , backgroundColor:'primary.main'  }}>
            {user == post?.post_user_id && ( 
              <IconButton  sx={{flexDirection:'row' , alignItems:'center'}} onClick={()=>setShowAlert(true)}>
                <DeleteIcon sx={{color:'primary.text',fontSize:18}} />
              </IconButton>
            )} 

            </Box>
          </Box>
          <Box sx={{ height:20, }} />
            <Typography sx={[Style?.title , {color:'primary.text',wordBreak:'break-all'}]}>
              {post?.post_title}
            </Typography>
            <Typography sx={[Style?.description , {color:'primary.text',wordBreak:'break-all'}]} 
              onClick={() => setSeeMore(false)} >
              {des}
            </Typography>
          <Box>
            <Slider {...{media:post?.post_media}}/>
          </Box>
 
          <Box sx={{ height:20 }} />
        
          <Typography sx={{color:'primary.text' , fontSize:13 , }}>{CalculateTime(post?.post_date)}</Typography>
           
          <Box sx={[Style?.footer , { backgroundColor:'primary.black' }]}>
            <Box sx={{flexDirection:'row' , alignItems:'center' , display : 'flex' , backgroundColor:Color?.main}}>
              
              <IconButton onClick={downvoting} >
                 <ExpandMoreSharpIcon sx={{color:'primary.text'}} />
              </IconButton>
              
              <Typography sx={[Style?.score , { color:'primary.text' }]}>{count}</Typography>

              <IconButton onClick={upvoting}>
                 <ExpandLessSharpIcon sx={{color:'primary.text'}} />
              </IconButton>
            </Box>
            
            <IconButton  sx={{ display : 'flex', flexDirection:'row' , alignItems:'center'  }} onClick={comments}>
              <Typography sx={{marginHorizontal:10,color:'primary.text',mr:1}}>{post?.comments_count }</Typography>
              <ForumOutlinedIcon sx={{color:'primary.text'}} />
            </IconButton>
            
          </Box>
        </Card>
      </>
      );
}



export default PostCard

 