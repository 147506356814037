import React, { useContext, useEffect, useState } from 'react';
import { Style } from './Style';
import { Avatar, Box, Card, CardMedia, IconButton, Typography } from '@mui/material';
import { useData } from '../../config/Provider';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { API , CalculateTime } from '../../config/functions';
import Vals from '../../config/Vars'
import { useParams } from 'react-router';
import { AlertsDialog, CommentsList, Slider, TextInput, VerifiedIcon } from '../../components';
import AddCommentIcon from '@mui/icons-material/AddComment';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';

const Post = ({nav}) => {
  
    const [ upVoted , srtUpVoted ] = useState(false) 
    const [ downVoted , srtDownVoted ] = useState(false) 
    const [ seeMore , setSeeMore ] = useState(false) 
    const [ deleted , setDelete ] = useState(false) 
    const [ showAlert , setShowAlert ] = useState(false) 
    const [ showAlertComment , setShowAlertComment ] = useState(false) 
    const [ alertCommentId , setAlertCommentId ] = useState(0) 
    const [ load , setLoad ] = useState(true) 
    const [ count , srtCount ] = useState(0) 
    const [ media , setMedia ] = useState([]) 
    const [ post , setPost ] = useState([]) 
    const [ commentss ,setComments  ] = useState([]) 
    const [ comment ,setComment  ] = useState('') 
    const [ commentCount ,setCommentCount  ] = useState(0) 
      
    
    const { Color , user , socket , setNotifyNum , logged } = useData()
    const { id } = useParams()

    const getData = async () => {
        await API.get(logged ? `user-post/${id}` :`/post/${id}` , Vals?.GET_HEADER )
        .then( ( response ) => {
            srtUpVoted(response?.data?.post?.voted==='votedUp'?true:false)
            srtDownVoted(response?.data?.post?.voted==='votedDown'?true:false)
            setPost( response?.data?.post )
            srtCount(response?.data?.post?.votes ? response?.data?.post?.votes : 0 )
            document.title=`SYPHEX | ${response?.data?.post?.post_title}` 
            setLoad(false)
        })
        .catch((e) => console.log(e) )

        await API.get( logged ? `/get-user-post-comments/${id}`:`/guest-get-post-comments/${id}` , Vals?.GET_HEADER )
        .then( ( response ) => {
            setComments( response?.data?.data )
            
        })
        .catch((e) => console.log(e) )
    }

    const DeleteAlert = () => {
      if (!logged) {
        return false
      }
        setShowAlert(true)
      }

    const SubmitComment =  async () => {

      if ( !comment.trim() ) {
          return false
      }
  
      const dataBody = {
        comment_text: comment,
        comment_content_id: post?.post_id,
      }
  
      await API.post(`/add-post-comment/`, dataBody , Vals?.POST_HEADER  )
      .then( ( response ) => {
  
          if (response?.data?.status === true) {

              setComments((e) => [ { 
                  user_username : response?.data?.username ,
                  comment_user_id : user ,
                  comment_date : 'Now' ,
                  comment_text : comment , 
                  comment_id : response?.data?.id,
                  user_profile_pic :  response?.data?.user_profile_pic
              }  , ...e])

              setComment('')
              setCommentCount(commentCount+1)
              if (post?.post_user_id != user) {
                getNotify()
              }

          } else {
            console.log(response?.data?.msg)
          }

      })
      .catch(function (e) { console.log(e) })
  
  }

  const getNotify = async () => {
    await socket?.emit('SendNewCommentNotify', { id : post?.post_id })
    await socket?.on('notification', async (data) => { 
      setNotifyNum(parseInt(data))
    })
  }

    useEffect(() => {
        getData()
        setCommentCount(post?.comments_count)

    } , [id])

    const VisitAccount = () => {
        nav(`/profile/${post?.post_user_id}`)
    }
  
    const VisitDiscussion = (id) => {
        nav(`/discussion/${post?.discussion_id}`)
    }

    const deleteComment = async (id) => {
      setAlertCommentId(id)
      setShowAlertComment(true)
    }

    const deleteCommentAction = async () => {
      if (!logged) {
        return false
      }
      await API.post( `/delete-comment-web/`,{id:alertCommentId} , Vals?.POST_HEADER )
      .then( ( response ) => {
        if (response?.data?.status === true) {
          const filter = commentss?.filter((i) => i.comment_id != alertCommentId)
          setComments(filter)
          setShowAlertComment(false)
        } else {
        //   Alert.alert('Error','Somthing went wrong !!')
        }
      })
      .catch((e) => console.log(e))
    }

    const upvoting = async () => {
      if (!logged) {
        return false
      }
        const dataBody = {
          content_id : post?.post_id 
        }
  
        await API.post( `/post-upvote/`,dataBody , Vals?.POST_HEADER )
        .then( ( response ) => {
          if (response?.data?.status === true) {
            srtUpVoted(response?.data?.checked)
            srtDownVoted(false)
            srtCount(!upVoted?downVoted?count+2:count+1:count-1)
          } else {
          //   Alert.alert('Error','Somthing went wrong !!')
          }
        })
        .catch((e) => console.log(e))
  
      }
  
      const downvoting = async () => {
        if (!logged) {
          return false
        }
        const dataBody = {
          content_id : post?.post_id 
        }
  
        await API.post( `/post-downvote/`,dataBody , Vals?.POST_HEADER )
        .then( ( response ) => {
          if (response?.data?.status === true) {
  
            srtDownVoted(response?.data?.checked)
            srtUpVoted(false)
            srtCount(!downVoted?upVoted?count-2:count-1:count+1)
            
          } else {
            console.log('Somthing went wrong !!')
          }
        })
        .catch((e) => console.log(e))
  
      }

      const deletePost = async () =>{
        const dataBody = {
          id : id
        }
  
        await API.post( `/remove-post/`,dataBody , Vals?.POST_HEADER )
        .then( ( response ) => {
          if (response?.data?.status === true) {
            nav('/')
          } else {
            console.log('Somthing went wrong !!')
          }
        })
        .catch((e) => console.log(e) )
      }
 
    const comments = () => {}

    const BorderColor = (e) => {
        var val = 'transparent'
        if (e == 0) {
          val = 'transparent'
        } else if ( e == 1 ) {
          val = '#d0cccc'
        } else if ( e == 2 ) {
          val = '#ceb99d'
        } else if ( e == 3 ) { 
          val = '#c9ac2e'
        }
        return val
    }
    
    const des = post?.post_description?.length > 100 ? `${post?.post_description.substring(0,seeMore?64:post?.post_description?.length)} ${seeMore? '...see more' : '' }` : post?.post_description
    const profilePic = post?.user_profile_pic ? `${Vals?.M_API}/profile-pictures/${post?.user_profile_pic}` :'/logo.png' 
  if (!load)
  return (<>

    <AlertsDialog {...{
          showAlert:showAlert,
          setShowAlert:setShowAlert,
          alertText:'Are you sure you want to delete this post !',
          alertFunc:deletePost
    }} />

    <AlertsDialog {...{
          showAlert:showAlertComment,
          setShowAlert:setShowAlertComment,
          alertText:'Are you sure you want to delete this comment !',
          alertFunc:deleteCommentAction
        }} />
    <Card sx={Style?.container }>
    <Box sx={Style?.header}>
      <Box sx={{ display:'flex' , flexDirection:'row',justifyContent:'flex-start',alignItems:'center' , backgroundColor:'primary.black'}}>
        
        <Box sx={{position:'relative',mr:1}}>
          <Avatar src={profilePic} sx={[Style?.profilePicture,{
              borderStyle:'solid',
              borderWidth:2,
              borderRadius:100,
              borderColor:BorderColor(post?.accountLevel)
          }]} alt={post?.user_username} />
          {post?.user_verified === 'y'&&(
            <VerifiedRoundedIcon sx={{color:'gold',position:'absolute',right:0,bottom:0,fontSize:15 }} />
          )}
        </Box>
        
        <Typography sx={[Style?.author,{color:'primary.text'}]} onClick={VisitAccount}  > u/{post?.user_username} -</Typography>
        <Typography ml={1} sx={[Style?.author,{color:'primary.text'}]} onClick={VisitDiscussion}> d/@{post?.discussion_title}</Typography>
      </Box>

      <Box sx={{ display:'flex' ,justifyContent:'flex-end',flexDirection:'row' , backgroundColor:'primary.main'  }}>
      {user == post?.post_user_id && ( 
        <IconButton  sx={{flexDirection:'row' , alignItems:'center'}} onClick={DeleteAlert}>
          <DeleteIcon sx={{color:'primary.text',fontSize:18}} />
        </IconButton>
      )} 
      </Box>
    </Box>

    <Box sx={{ height:20 }} />

    <Typography sx={[Style?.title , {color:'primary.text'}]}>
      {post?.post_title}
    </Typography>
    <Typography sx={[Style?.description , {color:'primary.text'}]} onClick={() => setSeeMore(false)} >
      {des}
    </Typography>
    <Box>
            <Slider {...{media:post?.post_media}}/>
          </Box>
    <Box sx={{ height:20 }} />
  
    <Typography sx={{color:'primary.text' , fontSize:13 , }}>{CalculateTime(post?.post_date)}</Typography>
     
    <Box sx={[Style?.footer , { backgroundColor:'primary.black' }]}>
      <Box sx={{flexDirection:'row' , alignItems:'center' , display : 'flex' , backgroundColor:Color?.main}}>
        
        <IconButton onClick={downvoting} >
          {/* <Entypo name="arrow-bold-up" size={24} color={upVoted?Colors?.d:Colors?.Typography} />
           */}
           <ExpandMoreSharpIcon sx={{color:'primary.text'}} />
        </IconButton>
        
        <Typography sx={[Style?.score , { color:'primary.text' }]}>{count}</Typography>

        <IconButton onClick={upvoting}>
          {/* <Entypo name="arrow-bold-down" size={24} color={downVoted?Colors?.d:Colors?.Typography} /> */}
           <ExpandLessSharpIcon sx={{color:'primary.text'}} />
        </IconButton>
      </Box>
      
      <IconButton  sx={{ display : 'flex', flexDirection:'row' , alignItems:'center'  }} onClick={comments}>
        <Typography sx={{marginHorizontal:10,color:'primary.text',mr:1}}>{post?.comments_count }</Typography>
        {/* <FontAwesome name="comments-o" size={24} color={Colors?.Typography} /> */}
        <ForumOutlinedIcon sx={{color:'primary.text'}} />
      </IconButton>
      
    </Box>
    <Box sx={{mr:1,ml:1,display : 'flex', flexDirection:'row' , alignItems:'center',justifyContent:'center' }}>
      <TextInput {...{type:'text',value:comment,set:setComment,placeholder:'leave a comment'}}/>
      <Box sx={{mt:2,height:'100%',display : 'flex', flexDirection:'column' , alignItems:'center',justifyContent:'center' }}>
        <IconButton onClick={SubmitComment} >
          <AddCommentIcon sx={{color:'primary.text'}} />
        </IconButton>
      </Box>
    </Box>
    <CommentsList comments={commentss} nav={nav} deleteComment={deleteComment} />
  </Card>
  </>
  )
}

export default Post