import { Avatar, CardMedia, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams, useRouteError } from 'react-router-dom'
import { Btn, DiscussionsCard, Empty, MemberCard } from '../../components'
import PostCard from '../../components/postCard/PostCard'
import { API } from '../../config/functions'
import { useData } from '../../config/Provider'
import Vals from '../../config/Vars'
import { Style } from './Style'
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';


const Profile = ({nav}) => {
  
  const [data , setData] = useState([])
  const [posts , setPosts] = useState([])
  const [members , setMembers] = useState([])
  const { id } = useParams()
  const {user,socket,setdiscussionNum,discussionNum,logged} = useData()
  const myDivRef = useRef(null);
  const [divWidth, setDivWidth] = useState(null)
  const [page, setPage] = useState(0)
  const [tabContent, setTabContent] = useState('posts')
  
  const leave = async () => {
    const dataBody = {
      id : id
    }
    await API.post( `/leave-member/` , dataBody , Vals?.POST_HEADER )
    .then( ( response ) => {
      if (response?.data?.status === true) {
        getData()
      } else {
        console.log('Error' , 'Something went wrong, try again later')
      }

    })
    .catch((e) => console.log(e) )
  }

  const Join = async () => {
    const dataBody = {
      id : id
    }
    await API.post( `/request-member/` , dataBody , Vals?.POST_HEADER )
    .then( ( response ) => {
      if (response?.data?.status === true) {
        getData()
      } else {
        console.log('Error' , 'Something went wrong, try again later')
      }
    })
    .catch((e) => console.log(e) )
  }

  const getPosts = async () => {
    await API.get(`${user? `/discussion-posts/${id}/${page}/new` :`/discussions-posts/${id}/${page}/new`}` , Vals?.GET_HEADER )
    .then( ( response ) => {
      if (page == 0) {
        setPosts(response?.data?.posts);
      } else {
        setPosts((prevData) => [...prevData, ...response?.data?.posts]);
      }
    })
    .catch((e) => console.log(e) )
  }

  const getData =  async () => {
    await API.get(`${user?'discussion-profile':'discussion'}/${id}/` , Vals?.GET_HEADER )
    .then( ( response ) => {
      setData( response?.data?.data[0] )
      document.title=`SYPHEX | @${response?.data?.data[0]?.discussion_title}` 
      setMembers( response?.data?.members )
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    getPosts()
  } , [page])
 
  useEffect( () => {
    getData()
    if (myDivRef.current) {
      setDivWidth(myDivRef.current.offsetWidth);
    }
  } , [])

  useEffect(() => {
    function handleScroll() {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      if (scrollTop + clientHeight >= (scrollHeight+150) && data?.length > 10) {
        setPage(page+1)
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (logged) {
      socket?.emit('DiscussionOnlineUsers' , { id : id , user_id : user })
      socket?.on('discussionNum', (date) => {
        if (date?.id == id) {
          setdiscussionNum(date?.count)
        }
      })
      return () => socket?.emit('LeaveDiscussionOnlineUsers' , {id : id , user_id : user})
    } else {
      socket?.on('discussionNum', (date) => {
        if (date?.id == id) {
          setdiscussionNum(date?.count)
        }
      })
      return () => socket?.emit('LeaveDiscussionOnlineUsersguest' , {id : id })
    }

  }, [])

  const coverPhoto =   data?.discussion_cover_pic ? `${Vals?.M_API}/covers/${data?.discussion_cover_pic}` : '/images/cover.png'  
  const profilePhoto = data?.discussion_profile_pic ?  `${Vals?.M_API}/profile-pictures/${data?.discussion_profile_pic}` : '/logo.png'
  
  return (
   
          <Box sx={Style?.root}>
            <Box sx={Style?.coverPhotoContainer} ref={myDivRef}>
                <CardMedia sx={Style?.coverPhoto} image={coverPhoto} alt='cover' priority="true"> 
                
                  <Box sx={Style?.profileInfoContainer}>
                    
                    <Avatar sx={Style?.profilePicture} src={profilePhoto} alt='/logo.png' priority="true"/>
                    
                    <Box sx={Style?.textInfo}>
                      <Typography variant='h4' sx={{color:'primary.text',fontSize:{xs:19,md:25}}}>@{data?.discussion_title}</Typography>
                      <Box sx={Style?.ActivityText}>
                        <Typography variant='h6' ml={{xs:0,md:0}} fontSize={15} sx={{color:'primary.text'}}>{data?.members} Members </Typography>
                        <Typography variant='h6' ml={{xs:2,md:5}} fontSize={15} sx={{color:'primary.text'}}>{logged ? discussionNum : data?.members} Online </Typography>
                      </Box>
                    </Box>
                    
                    <Box>
                      
                      {user == data?.discussion_user_id && (
                        <IconButton color="primary" aria-label="add to shopping cart" onClick={() => nav(`/discussion-settings/${id}`)}>
                          <SettingsTwoToneIcon sx={{color:'primary.text',fontSize:39}} />
                        </IconButton>
                      )}

                      {data?.IsJoined === 'n' ||  !data?.IsJoined && user && (
                        <Btn {...{title:'Join',func:Join}}/>
                      )}

                      {data?.IsJoined === 'y' &&  data?.IsAdmin === 'n' && (
                        <Btn {...{title:'leave',func:leave}}/>
                      )}
                      
                    </Box>
                  </Box>
                </CardMedia>

            </Box>


            <Box sx={Style?.tabsContainer}>
              <Box sx={[Style?.tabBtn,{borderColor:tabContent === 'posts' ? 'primary.blue':'primary.border',}]} onClick={() => setTabContent('posts')}>
                <Typography variant='p' color='primary.text'> Posts </Typography>
              </Box>
              <Box sx={[Style?.tabBtn ,{borderColor:tabContent === 'members' ? 'primary.blue':'primary.border',}]} onClick={() => setTabContent('members')}>
                <Typography variant='p' color='primary.text'> Members </Typography>
              </Box>
              <Box sx={[Style?.tabBtn ,{borderColor:tabContent === 'about' ? 'primary.blue':'primary.border',}]} onClick={() => setTabContent('about')}>
                <Typography variant='p' color='primary.text'> About </Typography>
              </Box>
            </Box>
            {tabContent === 'posts' && (
              <Box sx={{width:'100%',mt:3}}>
                {posts?.length > 0 ? posts?.map((post,key) => <PostCard key={key} {...{nav,post}}/>) : <Empty  {...{text:'no posts found'}}/>}
              </Box>
            )}

            {tabContent === 'about' && (
              <Box sx={{width:'100%',mt:3,borderColor:'primary.border',borderStyle:'solid',borderWidth:1}}>
                <Box sx={{p:3}}>
                    <Typography color='primary.text' variant='h6'> About </Typography>
                    <Typography color='primary.text' fontSize={20}> {data?.discussion_about === 'null' ? '...' : data?.discussion_about } </Typography>
                </Box>
              </Box>
            )}

            {tabContent === 'members' && (
              <Box sx={{width:'100%',mt:3}}>
                {members?.map((item,key) => <MemberCard key={key} {...{nav,item}}/>)}
              </Box>
            )}

          </Box>
 
  )
}

export default Profile