import { Box } from '@mui/system'
import React, { useState } from 'react'
import { PageHeader, TextInput } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'

const AddDiscussion = ({nav}) => {
    const [title, setTitle] = useState('')
    document.title='SYPHEX | Add discussion'

    const create = async () => {

        const dataBody = {
            title: title,
        }
    
        await API.post(`/add-discussion/`, dataBody , Vals?.POST_HEADER  )
        .then( ( response ) => {
          if (response?.data?.status === true) {
            nav(`/discussion/${response?.data?.id}`)
          } else {
              console.log( response?.data?.msg )
          }
        })
        .catch(function (e) { console.log(e) })
    
    }
  return (
    <Box>
        <PageHeader {...{title:'Create Discussion',btnTitle:'Create',func:create}} />
        <TextInput {...{value:title,set:setTitle,placeholder:'Title',type:'text',multiline:false}}/>
    </Box>
  )
}

export default AddDiscussion