import { Grid, IconButton, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { useData } from '../../config/Provider'
import { Empty, PostCard } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system'

export default function Home({nav}) {

  const [ data , setData ] = useState([])
  const [ page , setPage ] = useState(0)
  const {user} = useData()

  document.title='SYPHEX | Home'
  
  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
      await API.get(user ? `/users-posts/${page}/newest/` : `/posts/${page}/newest/`, Vals?.GET_HEADER )
      .then( ( response ) => {
        if (page == 0) {
        setData(response?.data?.data);
        } else {
        setData((prevData) => [...prevData, ...response?.data?.data]);
        }
      })
      .catch((e) => console.log(e) )

  };

  useEffect(() => {
    function handleScroll() {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      if (scrollTop + clientHeight >= (scrollHeight+150)  && data?.length > 10) {
        setPage(page+1)
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box  >
      {data?.length > 0 ? data?.map((post, key) => <PostCard key={key} {...{nav, post}} />) : <Empty {...{text:'No posts found'}} />} 
      <Box sx={{height: 100}} />
    </Box>
  );
}