import { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import Vals from '../../config/Vars';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import VerifiedIcon from '../VerifiedIcon/VerifiedIcon';

const Style = {
  root: {
    margin: '10px',
    backgroundColor:'primary.main'
  },
  avatar: {
    marginRight:2,
    height:50,
    width:50

  },
};

function Comment({ comment , nav , deleteComment }) {

  const [ upVoted , srtUpVoted ] = useState(comment?.voted==='votedUp'?true:false) 
  const [ downVoted , srtDownVoted ] = useState(comment?.voted==='votedDown'?true:false) 
  const [ count , setCount] = useState(comment?.votes?comment?.votes:0);
  const [ deleted , setDelete ] = useState(false) 
  const {user,logged} = useData()
  const handleUpvote = async () => {
    if (!logged) return false
    const dataBody = {
      content_id : comment?.comment_id 
    }

    await API.post( `/comment-upvote/`,dataBody , Vals?.POST_HEADER )
    .then( ( response ) => {
      if (response?.data?.status === true) {
        srtUpVoted(response?.data?.checked)
        srtDownVoted(false)
        setCount(!upVoted?downVoted?count+2:count+1:count-1)
      } else {
        console.log('Somthing went wrong !!')
      }
    })
    .catch((e) => console.log(e))
  };

  const handleDownvote = async() => {
    if (!logged) return false
    const dataBody = {
      content_id : comment?.comment_id 
    }

    await API.post( `/comment-downvote/`,dataBody , Vals?.POST_HEADER )
    .then( ( response ) => {
      if (response?.data?.status === true) {
          srtDownVoted(response?.data?.checked)
          srtUpVoted(false)
          setCount(!downVoted?upVoted?count-2:count-1:count+1)
      } else {
        console.log('Somthing went wrong !!')
      }
    })
    .catch((e) => console.log(e))
  };

  const profilePic = comment?.user_profile_pic ? `${Vals?.M_API}/profile-pictures/${comment?.user_profile_pic}` :'/logo.png' 

  const BorderColor = (e) => {
    var val = 'transparent'
    if (e == 0) {
      val = 'transparent'
    } else if ( e == 1 ) {
      val = '#d0cccc'
    } else if ( e == 2 ) {
      val = '#ceb99d'
    } else if ( e == 3 ) { 
      val = '#c9ac2e'
    }
    return val
  }

  return (
    <Card sx={Style?.root}>
      <CardHeader 
        onClick={() => nav(`/profile/${comment?.user_id}`)}
        avatar={
          <>
            <Avatar sx={[Style?.avatar , {
                borderStyle:'solid',
                borderWidth:2,
                borderRadius:100,
                borderColor:BorderColor(comment?.accountLevel)
            }]} src={profilePic} />
            {comment?.user_verified === 'y' && ( <VerifiedIcon /> )}
          </>
        }
        title={`@${comment?.user_username}`}
        subheader={<Typography sx={{  color:'primary.text',fontSize:12 }}> {comment?.comment_date === 'Now' ? 'Now' : new Date(comment?.comment_date).toLocaleString()} </Typography>}
        sx={{color:'primary.text' ,  cursor:'pointer'}}
      />
      <CardContent>
        <Typography color='primary.text'>{comment?.comment_text} </Typography>
      </CardContent>
      <Box sx={{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center' ,
      }} >
         <Box sx={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center' ,
         }}>
         <IconButton aria-label="upvote" onClick={handleDownvote}>
            <ExpandMoreSharpIcon   sx={{color:'primary.text'}}/>
          </IconButton>
          <Typography color='primary.text'>{count}</Typography>
          <IconButton aria-label="downvote" onClick={handleUpvote}>
            <ExpandLessSharpIcon  sx={{color:'primary.text'}}/>
          </IconButton>     
         </Box>
        {comment?.comment_user_id == user && (
          <IconButton aria-label="downvote" onClick={() => deleteComment( comment?.comment_id )}>
            <DeleteTwoToneIcon  sx={{color:'primary.text'}}/>
          </IconButton>
        )}
        
      </Box>
    </Card>
  );
}

function CommentsList({ comments , nav , deleteComment }) {
  return (
    <>
      {comments?.map((comment,key) => (
        <Comment key={key} comment={comment} nav={nav} deleteComment={deleteComment} />
      ))}
    </>
  );
}

export default CommentsList;