import React from 'react'
import { AffiliateData, AffiliateTerms } from '../../components'
import { Box } from '@mui/material'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useData } from '../../config/Provider'
import { useEffect } from 'react'
import { useState } from 'react'

const Affiliate = () => {

    const [ check , setCheck ] = useState(false)
    const [ load , setLoad ] = useState(true)
    const [ data , setData ] = useState([])
    
    const getData = async () => {
        await API.get(`/web-check-affiliate/` , Vals?.GET_HEADER)
        .then( ( res ) => {
            setCheck(res?.data?.check)
            setData(res?.data)
            setLoad(false)
        } ).catch((e) => console.log(e))
    }

    useEffect(() => {
        getData()
    } , [] )

    if ( !load )
    return (
        <Box>
            { check ? <AffiliateData {...{info:data}} /> : <AffiliateTerms {...{setSection:setCheck}} /> }
        </Box>
    )
}

export default Affiliate